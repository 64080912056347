.button {
    display: flex !important;
    flex-direction: column;
    flex: 1;
    vertical-align: middle !important;
    align-items: center;
    justify-content: center;
    border: 2px solid black !important;
}

.topButton{
    @extend .button;
    margin-top: 40%;
    margin-bottom: 20%;
}


.bottomButton{
    @extend .button;
    margin-top: 20%;
    margin-bottom: 40%;
}

.controlPanel {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    width: 20%
}